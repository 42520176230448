<template>
<v-card
  max-width="100%"
  class="my-8"
  outlined
  :loading="saving"
  >
  <template v-if="!get_printing_possible">
    <v-card-title>
      Print Server
    </v-card-title>
    <v-card-text>
      Add Carrier accounts to be able to connect and {{ $appName }} Print Server. Print Server allows you to 
      automatically connect printers and label printers, to enable automatic 
      label printing without print dialogs when fulfilling orders.
    </v-card-text>
  </template>
  <template v-else>
    <v-card-title>
      Print Server
    </v-card-title>
    <v-card-text>
      <p>Open {{ $appName }} Printserver App on the computer connected to the printers and paste the address it's listening to below. Make sure the computer is on and not sleeping.</p>
          <v-text-field
            outlined
            label="Connection key"
            :value="`${company.id}-${company.printserver_key}`"
            append-outer-icon="mdi-content-copy"
            @click:append-outer="copytext(`${company.id}-${company.printserver_key}`)"
            style="width:32em;"
            readonly
            />
    </v-card-text>
    <v-card-actions class="d-flex flex-column align-start mb-8">
      <v-card-subtitle>
        Download {{ $appName }} Printserver app
      </v-card-subtitle>
      <div class="">
        <v-btn
          :href="href.mac"
          rounded
          outlined
          class="mx-4"
          >Mac OSX</v-btn>
          <v-btn
          :href="href.win"
          rounded
          outlined
          class="mx-4"
          >Windows</v-btn>
      </div>
    </v-card-actions>
  </template>
  
</v-card>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import { mapMultiRowFields, mapFields } from 'vuex-map-fields';
import firebase from 'firebase/app'
import 'firebase/storage'

export default {
  components: {
    
  },
  computed: {
    ...mapState({
      company: state => state.warehouse.company,
      config: state => state.app.config,
    }),
    ...mapFields(['warehouse.company.print_server_address']),
    ...mapMultiRowFields({
      p_map: 'warehouse.company.printer_map'
    }),
    ...mapGetters({
      get_printing_possible: 'warehouse/get_printing_possible',
      get_available_print_formats: 'app/get_available_print_formats'
    })
  },
  
  
  filters: {
    // CurrencyFilter,
    // DateFilter,
  },
  data() {
    return {
      loading: false,
      loading_connection: false,
      saving: false,
      errormsg: '',
      disabled_submit: true,
      target_types: [
        // {name: 'Certificates', id: 'certificates'},
        {name: 'Shipping Labels', id: 'labels'},
        // {name: 'Passports', id: 'passports'},
      ],
      href: {
        mac: '',
        win: '',
      }
    }
  },
  methods: {
    copytext(text){
        this.$store.commit('app/SET_SNACK_BAR', 'Copied')
    navigator.clipboard.writeText(text)
    },
    trim(){
      this.print_server_address = this.print_server_address.replace(/ /gi, '')
    },
    async disconnect_server(){
      await this.$store.dispatch('warehouse/disconnect_printer')
    },
    async connectServer(){
      this.loading_connection = true
      if(!this.company.print_server_connected) this.errormsg = `Could not connect on address ${this.print_server_address}`
      this.loading_connection = false
    },
    removePrinter(index){
      this.company.printer_map.splice(index, 1)
      this.$store.commit('warehouse/SET_COMPANY', {printer_map: this.company.printer_map})
      this.savePrinterMap()
      this.disabled_submit = false
    },
    addPrinter(){
      this.$store.commit('warehouse/SET_COMPANY', {printer_map: this.company.printer_map.concat([{
          target: 'labels',
          printer_id: '',
          format: ''
        }])})
        this.savePrinterMap()
        this.disabled_submit = false
    },
    async savePrinterMap(){
      console.log('save');
      this.saving = true
      await this.$store.dispatch('warehouse/save_printers')
      this.disabled_submit = true
      this.saving = false
    },
  },
  async mounted(){
      const href = {
        mac: await firebase.storage().ref().child(`public/${this.config.downloads.mac}`).getDownloadURL(),
        win: await firebase.storage().ref().child(`public/${this.config.downloads.win}`).getDownloadURL()
      }
      this.href = href
      
    }
}
</script>